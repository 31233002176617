import {Outlet, Navigate} from 'react-router-dom'
import { useSelector } from 'react-redux';

const PrivateRoute = () => {
    const auth = useSelector(state => state.firebase.auth);
    return (
        auth.uid ? <Outlet /> : <Navigate to="/authentication"/>
    )

}
export default PrivateRoute;