import {combineReducers} from 'redux';
import {firebaseReducer} from 'react-redux-firebase';
import {firestoreReducer} from 'redux-firestore';
import authReducer from './authReducer';
import userReducer from './userReducer';
import donationReducer from './donationReducer';
import adsReducer from './adsReducer'
import dashboardReducer from './dashboardReducer'
import deleteProfileReducer from './deleteProfileReducer'


export default combineReducers({
    auth: authReducer,
    user: userReducer,
    donation: donationReducer,
    ads: adsReducer,
    dashboard: dashboardReducer,
    deleteProfile: deleteProfileReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer
})