/* eslint-disable react/style-prop-object */
import React from 'react';
import styles from '../ads/Ads.module.scss';
import Skyscrapper from '../ads/Skyscrapper';
import Box from '../ads/Box';
import Leaderboard from '../ads/Leaderboard';


export const amazon = () => {
    return (
        <div className={styles.amazon}>
            {/* <iframe
            src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=26&l=ez&f=ifr&linkID=a4beff522a48e8542b9dfdb49913ef89&t=tabfund-21&tracking_id=tabfund-21"
            width="468" height="60" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe> */}
        </div>
    )
}

export const ADS_MAP = [
    {name: 'Skyscrapper', component: Skyscrapper},
    {name: 'Box', component: Box},
    {name: 'Leaderboard', component: Leaderboard},
]