import * as actions from '../actions/actionTypes';

const initialState = {
    error: null,
    loading: false,
    success: false
}

const UserReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.USER_START:
            return {
                ...state,
                error: null,
                loading: true,
                success: false
            }
        case actions.USER_HIDE_MESSAGE:
            return {
                ...state,
                error: null,
                loading: false,
                success: false
            }
        case actions.USER_FINISH:
            return {
                ...state,
                loading: false
            }
        case actions.USER_SUCCESS:
            return {
                ...state,
                error: null,
                success: true
            }
        case actions.USER_FAIL:
            return {
                ...state,
                error: action.payload,
                success: false
            }
        default:
            return state
    }

}

export default UserReducer;