import * as actions from '../actions/actionTypes';

const initialState = {
    error: null,
    loading: false,
    success: false,
    environmentSubjects: [],
    healthSubjects: [],
    newTechnologiesSubjects: [],
    socioEconomicsSubjects: [],
}

const DonationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_SUBJECTS:
            return {
                ...state,
                error: null,
                environmentSubjects: action.payload.environmentSubjects,
                healthSubjects: action.payload.healthSubjects,
                newTechnologiesSubjects: action.payload.newTechnologiesSubjects,
                socioEconomicsSubjects: action.payload.socioEconomicsSubjects,
            }
        case actions.DONATION_START:
            return {
                ...state,
                loading: true,
                error: null,
                success: false,
            }
        case actions.DONATION_FINISH:
            return {
                ...state,
                loading: false
            }
        case actions.DONATION_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                success: true,
            }
        case actions.DONATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            }
        case actions.DONATION_CLEAR:
            return {
                error: null,
                loading: false,
                success: false,
                environmentSubjects: [],
                healthSubjects: [],
                newTechnologiesSubjects: [],
                socioEconomicsSubjects: [],
            }
        default:
            return state
    }

}
export default DonationReducer;