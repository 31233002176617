import React from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import Ads from './ads/Ads';
import firebase from './firebase';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import store from './store/storeIndex';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css'; 

const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
}

const initApp = () => {

    require('./index.css')
    const rootElement = document.getElementById('root')
    const adsElement = document.getElementById('ads')
    if (rootElement && rootElement.hasChildNodes()) {
        hydrate(<Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ReactReduxFirebaseProvider>
        </Provider>, rootElement, () => { })
    } else {
        render(<Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ReactReduxFirebaseProvider>
        </Provider>, rootElement)
    }

    render(<Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <Ads />
        </ReactReduxFirebaseProvider>
    </Provider>, adsElement)
}

initApp()
serviceWorker.unregister();
