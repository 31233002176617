import React from 'react'
import { ADS_MAP } from '../config/adsConfigs'

const Ads = () => {

    return (
        <div>
            {
                ADS_MAP.map(ad => (
                    <ad.component key={ad.name}/>
                ))
            }
        </div>
    )
}

export default Ads
