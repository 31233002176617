import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';

// var firebaseConfig = {
//     apiKey: process.env.REACT_APP_APIKEY,
//     authDomain: "tabfund-c7198.firebaseapp.com",
//     databaseURL: "https://tabfund-c7198.firebaseio.com",
//     projectId: "tabfund-c7198",
//     storageBucket: "tabfund-c7198.appspot.com",
//     messagingSenderId: "233433109072",
//     appId: "1:233433109072:web:ae0b87c5b071c2082af755",
//     measurementId: "G-8F7TFV2Q2M"
//   };
  var firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };
// firebase.initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.analytics();
firebase.storage();

export default firebase;