//AUTH
export const AUTH_START = 'AUTH_START';
export const AUTH_FINISH = 'AUTH_FINISH';
export const AUTH_FAIL = 'AUTH_FAIL'; 
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_EMAIL_SENT = 'AUTH_EMAIL_SENT';
export const AUTH_CLEAR = 'AUTH_CLEAR';
export const AUTH_TERMS_AND_CONDITIONS = 'AUTH_TERMS_AND_CONDITIONS';
export const AUTH_USERNAME_FAIL = 'AUTH_USERNAME_FAIL';

//USER
export const USER_START = 'USER_START';
export const USER_HIDE_MESSAGE = 'USER_HIDE_MESSAGE';
export const USER_FINISH = 'USER_FINISH';
export const USER_FAIL = 'USER_FAIL'; 
export const USER_SUCCESS= 'USER_SUCCESS';

//BOOKMARK
export const BOOKMARK_START = 'BOOKMARK_START';
export const BOOKMARK_FINISH = 'BOOKMARK_FINISH';
export const BOOKMARK_SUCCESS = 'BOOKMARK_SUCCESS';
export const BOOKMARK_FAIL = 'BOOKMARK_FAIL';
export const ADD_BOOKMARK = 'ADD_BOOKMARK';
export const FETCH_BOOKMARK = 'FETCH_BOOKMARK';

//DONATION
export const DONATION_START = 'DONATION_START';
export const DONATION_FINISH = 'DONATION_FINISH';
export const DONATION_FAIL = 'DONATION_FAIL'; 
export const DONATION_CLEAR = 'DONATION_CLEAR'; 
export const DONATION_SUCCESS= 'DONATION_SUCCESS';
export const FETCH_SUBJECTS= 'FETCH_SUBJECTS';

//GOOGLE
export const FETCH_GOOGLE_SUGGESTIONS = 'FETCH_GOOGLE_SUGGESTIONS';
export const FETCH_GOOGLE_RESULTS = 'FETCH_GOOGLE_RESULTS';

//GIFT
export const NEW_GIFT = 'NEW_GIFT';
export const FETCH_GIFTS = 'FETCH_GIFTS';
export const GIFT_START = 'GIFT_START';
export const GIFT_FINISH = 'GIFT_FINISH';
export const GIFT_FAIL = 'GIFT_FAIL'; 
export const GIFT_SUCCESS= 'GIFT_SUCCESS';

//WIDGETS SETTING
export const UPDATE_WIDGETS = 'UPDATE_WIDGETS';
export const FETCH_WIDGETS = 'FETCH_WIDGETS';

//ADS
export const CHECK_AD_BLOCKS = 'CHECK_AD_BLOCKS';
export const ADS_FAIL = 'ADS_FAIL';

//MODALS
export const OPEN_DASHBOARD_MODAL = 'OPEN_DASHBOARD_MODAL';
export const CLOSE_DASHBOARD_MODAL = 'CLOSE_DASHBOARD_MODAL';
export const OPEN_DELETE_PROFILE_MODAL = 'OPEN_DELETE_PROFILE_MODAL';
export const CLOSE_DELETE_PROFILE_MODAL = 'CLOSE_DELETE_PROFILE_MODAL';