import * as actions from '../actions/actionTypes';


const initialState = {
    openDashboardModal: false,
    openTab: "profile"
}

const DashboardReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.OPEN_DASHBOARD_MODAL:
            return {
                openDashboardModal: true,
                openTab: action.payload
            }
        case actions.CLOSE_DASHBOARD_MODAL: 
             return {
                openDashboardModal: false,
                openTab: "profile"
             }
        
        default:
            return state
    }

}
export default DashboardReducer;