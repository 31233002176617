import React, { lazy, Suspense, useEffect } from 'react';
import './App.css';
import {Route, useNavigate, Routes, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase'
import $ from "jquery"
import PrivateRoute from './PrivateRoute';

const Home = lazy(() => import('./components/Home'))
const Authentication = lazy(() => import('./components/authentication/Authentication'))


const App = () => {

  const auth = useSelector(state => state.firebase.auth);
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoaded(auth)) {
      if (auth.uid) {
        navigate('/')
      }
    }
  }, [auth, navigate])



  return (
    <div className="App" id='app'>
      <header className="App-header">
        {
          isLoaded(auth) &&
            <Suspense fallback={<div />}>
              <Link to="/ads.txt" download target="_blank" />
              <Routes>
                <Route element={<PrivateRoute />}>
                  <Route element={<Home />} path='/' exact />
                </Route>
                <Route element={<Authentication />} path='/authentication' />
              </Routes>
            </Suspense>
        }
      </header>
    </div>
  );
}

var maxWidth = $('#outer').width();
var maxHeight = $('#outer').height();

$(window).resize(function (evt) {
  var $window = $(window);
  var width = $window.width();
  var height = $window.height();
  var scale;

  // early exit
  if (width >= maxWidth && height >= maxHeight) {
    $('#outer').css({ '-webkit-transform': '' });
    $('#wrap').css({ width: '', height: '' });
    return;
  }

  scale = Math.min(width / maxWidth, height / maxHeight);

  $('#outer').css({ '-webkit-transform': 'scale(' + scale + ')' });
  $('#wrap').css({ width: maxWidth * scale, height: maxHeight * scale });
});


export default App;
