import React, { useEffect } from 'react'
import styles from './Ads.module.scss';

const Leaderboard = () => {
    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({})
    }, [])

    return (
        <div className={styles.leaderboard}>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5755274383907096"
     crossorigin="anonymous"></script>
            <ins class="adsbygoogle"
                style={{
                    display: 'inline-block',
                    width: '728px',
                    height: '90px'
                }}
                data-ad-client="ca-pub-5755274383907096" data-ad-slot="4905956797"></ins>
        </div>
    )
}

export default Leaderboard
