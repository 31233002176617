import * as actions from '../actions/actionTypes';

const initialState = {
    error: null,
    loading: false,
    logged: false,
    emailSent: false,
    success: false,
    usernameError: null,
    termsAndConditions: {}
}

const AuthReducer =  (state = initialState, action) => {

    switch (action.type) {
        case actions.AUTH_START:
            return {
                ...state,
                error: null,
                loading: true,
                logged: false,
                emailSent: false,
                success: false,
                usernameError: null,
            }
        case actions.AUTH_FINISH:
            return {
                ...state,
                loading: false
            }
        case actions.AUTH_SUCCESS:
            return {
                ...state,
                error: null,
                logged: true,
                success: true
            }
        case actions.AUTH_FAIL:
            return {
                ...state,
                error: action.payload,
                logged: false,
                emailSent: false,
                success: false
            }
        case actions.AUTH_LOGOUT:
            return {
                ...state,
                logged: false
            }
        case actions.AUTH_EMAIL_SENT:
            return {
                ...state,
                emailSent: !state.emailSent
            }
        case actions.AUTH_TERMS_AND_CONDITIONS:
            return {
                ...state,
                termsAndConditions: action.payload
            }
        case actions.AUTH_USERNAME_FAIL:
            return {
                ...state,
                usernameError: action.payload,
            }
        case action.AUTH_CLEAR:
            return {
                error: null,
                loading: false,
                logged: false,
                emailSent: false,
                success: false,
                usernameError: null,
                termsAndConditions: {}
            }
        default:
            return state
    }

}
export default AuthReducer;