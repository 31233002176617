import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
    .use(initReactI18next)
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        keySeparator: false,
        supportedLngs: ['en', 'pt'],
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['sessionStorage', 'navigator', 'path', 'htmlTag', 'subdomain'],
            caches: ['sessionStorage'],
        },
        backend: {
            loadPath: '/locales/{{lng}}/translations.json'
        },
        react: {
            useSuspense: false
        }
    })

export default i18n;