import * as actions from '../actions/actionTypes';


const initialState = {
    openDeleteProfileModal: false
}

const DeleteProfileReducer =  (state = initialState, action) => {

    switch (action.type) {
        case actions.OPEN_DELETE_PROFILE_MODAL:
            return {
                openDeleteProfileModal: true
            }
        case actions.CLOSE_DELETE_PROFILE_MODAL: 
             return {
                openDeleteProfileModal: false
             }
        
        default:
            return state
    }

}
export default DeleteProfileReducer;