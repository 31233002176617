import React, {useEffect} from 'react'
import styles from './Ads.module.scss';

const Box = () => {

    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({})
    }, [])

    return (
        <div className={styles.box}>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5755274383907096"
                crossorigin="anonymous"></script>

            <ins class="adsbygoogle"
                style={{
                    display:'inline-block',
                    width:'200px',
                    height:'200px'
                }}
                data-ad-client="ca-pub-5755274383907096" data-ad-slot="2215682766"></ins>
        </div>
    )
}

export default Box
